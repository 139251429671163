import axios from "axios"

import config from "../service/config"

import { getCookie } from "../service/helper"

let headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
}

let myAxios = axios.create({
    baseURL: config.API_URL,
    headers: headers,
    // auth: {
    //     username: config.GATSBY_DRUPAL_USERNAME,
    //     password: config.GATSBY_DRUPAL_PASSWORD
    // }
})

let staticAxios = axios.create({
    baseURL: 'https://hgsiapps.teamhgs.com/HHbillpayment',
    headers: headers,
})

myAxios.interceptors.request.use(function(config) {
    return config;
}, function(error) {
    return Promise.reject(error);
});

myAxios.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    return Promise.reject(error)
});

export function initHeaders() {
    myAxios.defaults.headers.common['Authorization'] = "Bearer " + getCookie('login_id')
}

const api = {
    getDoctors(params) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.DOCTORS.DOCTORS_DATA, {
                params: {...params}
            })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    findDoctors(query) {
        return new Promise((resolve, reject) => {
            //myAxios.get(config.DOCTORS.DOCTORS_DATA+'?salutation=Dr.&keyword='+query)
            myAxios.get(config.DOCTORS.DOCTORS_DATA+'?keyword='+query) // salutation=Dr.& Removed as per Atul suggestion
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getSpecialities() {
        return new Promise((resolve, reject) => {
            myAxios.get(config.DOCTORS.SPECIALITY)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getBookingSlots(doctorId) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.DOCTORS.BOOKING_SLOTS + "/" + doctorId)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })        
    },

    getSlotTypes() {
        return new Promise((resolve, reject) => {
            myAxios.get(config.DOCTORS.GET_SLOT_TYPES)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })        
    },

    getChargeBySlotType(type){
        return new Promise((resolve, reject) => {
            myAxios.get(config.DOCTORS.GET_CHARGE_BY_SLOT_TYPES + '/' + type)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    getRoomNo(code){
        return new Promise((resolve, reject) => {
            myAxios.get(config.DOCTORS.GET_ROOM_NO + '/' + code)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    getAppoinmentById(id) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.DOCTORS.GET_APPOINTMENT+'/'+id)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })        
    },
    getAllAppoinmentByHHNumber(hhNumber) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.DOCTORS.ALL_APPOINTMENT_BY_HH+'/'+hhNumber)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })        
    },
    bookAnAppoinment(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.DOCTORS.BOOK_APPOINTMENT, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })        
    },
    getAppoinmentCharges(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.DOCTORS.GET_APPOINTMENT_CHARGES, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })        
    },
    blockTimeSlot(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.DOCTORS.BLOCK_TIME_SLOT, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })        
    },
    getSpecialtiesAndServices(query) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.DOCTORS.SPECIALITY_SEARCH+query)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    emailInterimBill(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.EMAIL_INTERIM_BILL, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getInterimBill(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.GET_INTERIM_BILL, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getAllMedicalReports(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.GET_ALL_MEDICAL_REPORTS, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getMedicalReportDetails(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.GET_MEDICAL_REPORT_DETAILS, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getPatientDetailsFromOrderNo(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.GET_PATIENT_DETAILS_FROM_ORDER_NO, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getTeleconsultation(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.DOCTORS.TELECONSULTATION, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getTeleAvailDates(drId) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.DOCTORS.TELECONSULTATION_AVAIL_DATE+'/'+drId)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    
    getSlotBookingApp(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.SLOT_BOOKING, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
}

export default api
